@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500&family=Poppins:wght@400;500;600;700&display=swap');

/* GENERAL */
html,
body,
* {
  font-family: 'Nunito', sans-serif;
  color: #282931;
  font-weight: 500;
}

html,
body {
  margin: 0;
  padding: 0;
}

.medium-zoom-overlay, .medium-zoom-image--opened {
  z-index: 999;
}

/* LAYOUT */


.layout-container {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.layout-loading-error, .layout-loading-error span {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin: 40px 0;
}

.layout-nav-container {
  height: 56px;
  background-color: #fff;
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
}

.layout-nav-logo-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  height: 100%;
}

.layout-header-title-container {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 20px;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
}

.layout-header-title {
  font-family: 'Poppins', sans-serif;
  color: #fff;
  width: 100%;
  font-size: 30px;
  font-weight: 800;
}

.layout-header-type {
  color: #fff;
  font-weight: 300;
  font-size: 26px;
}

.layout-header-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 184px;
  width: 100%;
  -webkit-box-align: relative;
      -ms-flex-align: relative;
          align-items: relative;
  background-color: #81a0c8;
  border-bottom: 16px solid #315397;
  background-image: url('./assets/header-bg.png');
  background-size: cover;
  background-position: left;
}

.layout-content-header-container {
  background-color: #315397;
  border-top-left-radius: 20px;
  padding: 20px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.layout-content-header-title, .layout-content-header-title span,
.clock-count {
  color: #F5F5FB;
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 800;
  line-height: 55px;
  margin: 0;
}

.clock-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.clock-count {
  margin-left: 5px;
}

.layout-content-container {
  width: 1000px;
  margin: 40px auto;
  height: auto;
  border-radius: 20px;
  border-top-right-radius: 0;
  background-color: #F5F5FB;
  -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
          filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
}

.question-instructions {
  color: #315397;
  border: 1px solid #315397;
  background-color: #D7D9EB;
  padding: 10px;
  border-radius: 5px;
  font-style: italic;
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 30px;
}

.question {
  font-size: 24px;
  font-weight: 700;
}

.question-image-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.question-image {
  max-width: 100%;
  max-height: 500px;
  -o-object-fit: contain;
     object-fit: contain;
}

.layout-body {
  padding: 30px;
  border: 2px solid #315397;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.next-step-button {
  background-color: #315397;
  border: none;
  border-radius: 500px;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  display: block;
  margin: 20px auto 0 auto;
  cursor: pointer;
  -webkit-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.next-step-button:hover {
  background-color: #E73333;
}

.layout-footer {
  background-color: #282931;
  padding: 50px 0;
  border-top: 16px solid #315397;
  margin-top: auto;
}

.layout-footer-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  height: 100%;
  padding: 0 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.layout-footer-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  color: #F5F6FA;
}

.layout-footer-column_middle {
  border-right: 2px solid #F5F6FA;
  border-left: 2px solid #F5F6FA;
  padding: 0 20px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.layout-footer-column .logo-arinfo {
  max-width: 100%;
}

@media screen and (max-width: 1100px) {
  .layout-footer-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .layout-footer-column_middle {
    border-right: none !important;
    border-left: none !important;
    border-top: 2px solid #F5F6FA;
    border-bottom: 2px solid #F5F6FA;
    padding: 20px 0 !important;
  }

  .layout-footer-column {
    -ms-flex-preferred-size: 0 !important;
        flex-basis: 0 !important;
  }

  .layout-footer-column:first-child {
    padding-right: 0 !important;
    padding-bottom: 20px;
  }

  .layout-footer-column:last-child {
    padding-left: 0 !important;
    padding-top: 20px;
  }

  .layout-footer-column .logo-arinfo {
    max-width: 300px;
  }
}

@media screen and (max-width: 1440px) {
  .layout-footer-legal-container {
    margin: 20px 20px 0 20px !important;
  }
}

.layout-footer-column-text {
  color: #F5F6FA;
  font-size: 16px;
  margin-top: 0;
}

.layout-footer-column .column-title {
  font-size: 24px;
  color: #9BA1CB;
  font-weight: 600;
  margin-top: 0;
}

.layout-footer-column .link-block:not(:last-child) {
  margin-bottom: 10px;
}

.layout-footer-column .link-block .link {
  color: #9BA1CB;
  font-size: 16px;
  font-weight: 500px;
  text-decoration: none;
  cursor: pointer;
}

.layout-footer-column .link-block .link:hover {
  text-decoration: underline;
}

.layout-footer-column .link-block .link:not(:last-child) {
  margin-bottom: 10px;
}

.layout-footer-column .link-block .description {
  font-size: 16px;
  font-weight: 500px;
  color: #D7D9EB;
  border-left: 1px solid #D7D9EB;
  margin-left: 5px;
  padding-left: 5px;
}

.layout-footer-legal-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 20px auto 0 auto;
  padding-top: 20px;
  border-top: 2px solid #F5F6FA;
  max-width: 1400px;
}

.layout-footer-legal-link{
  text-decoration: none;
  color: #F5F6FA;
}

.layout-footer-legal-link:hover{
  text-decoration: underline;
}

/* FORM */
.form-submit {
  background-color: #315397;
  border: none;
  border-radius: 500px;
  color: #fff;
  font-size: 16px;
  margin-left: auto;
  height: 42px;
  width: 100px;
  cursor: pointer;
  -webkit-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.form-submit:hover {
  background-color: #E73333;
}

.form-submit:disabled {
  pointer-events: none;
  opacity: .5;
}

.form-container {
  width: 100%;
  color: rgb(50, 70, 80);
}

.form-help {
  margin: 30px 0 20px;
}

.form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 20px;
}

.form-col {
  -webkit-box-flex: 50%;
      -ms-flex: 50%;
          flex: 50%;
}

.form-col:not(:last-child){
  margin-right: 20px;
}

.error-message {
  color: #E73333;
  font-size: 14px;
  margin-top: 5px;
  display: block;
}

.form-input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  height: 38px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0px 10px;
  font-family: 'Nunito', sans-serif !important;
  font-size: 16px;
  color: rgb(50, 70, 80);
}

.form-input-error {
  border-color: #E73333;
  -webkit-box-shadow: 0 0 0 1px #E73333;
          box-shadow: 0 0 0 1px #E73333;
}

.form-textarea-error {
  border: 1px solid #E73333 !important;
  -webkit-box-shadow: 0 0 0 1px #E73333;
          box-shadow: 0 0 0 1px #E73333;
}

.form-input:disabled {
  opacity: 0.7;
  background-color: #f2f2f2;
  pointer-events: none;
}

.form-input:-moz-read-only {
  opacity: 0.7;
  background-color: #f2f2f2;
  pointer-events: none;
}

.form-input:read-only {
  opacity: 0.7;
  background-color: #f2f2f2;
  pointer-events: none;
}

.form-datepicker {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.form-select {
  font-size: 16px;
}

.form-textarea {
  min-height: 110px;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  resize: none;
  padding: 10px 10px;
  font-size: 16px;
  color: rgb(50, 70, 80);
}

.form-textarea:disabled {
  opacity: 0.7;
  background-color: #f2f2f2;
  pointer-events: none;
}

.form-input:focus,
.form-textarea:focus {
  border-color: #2684ff;
  outline: none;
}

.form-input-error:focus,
.form-textarea-error:focus {
  border-color: #E73333;
  outline: none
}

.form-label{
  width: 100%;
}

.form-recaptcha{
  margin-bottom: 20px
}

/* INSTRUCTIONS */
.instructions-paragraph {
  font-size: 18px;
  color: #315397;
  font-weight: 700;
}

.instructions-title {
  font-size: 20px;
  color: #315397;
  font-weight: 700;
}

.instructions-text {
  font-size: 16px;
  font-weight: 500;
}

.instructions-types-description {
  font-style: italic;
  font-size: 14px;
  font-weight: 400;
  margin: 0
}

.instructions-list {
  padding-left: 20px;
}

/* END */
.end-step {
  text-align: center;
}

.end-paragraph {
  font-size: 18px;
  color: #324650;
  line-height: 18px;
  margin-bottom: 10px;
}

/* CHECKBOX */

.checkbox-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.checkbox-label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  color: #324650;
  font-size: 16px;
}

.checkbox-container:not(:last-child){
  margin-bottom: 12px;
}

.checkbox-label .checkbox-input, .illustrated-checkbox-label .checkbox-input {
  display: none;
}

.checkbox-label .checkbox-input:checked+.checkbox-box, .illustrated-checkbox-label .checkbox-input:checked+.checkbox-box{
  background: #2e5596;
  border-color: #2e5596;
}

.checkbox-label .checkbox-input:checked+.checkbox-box:after,
.illustrated-checkbox-label .checkbox-input:checked+.checkbox-box:after {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}

.checkbox-label .checkbox-box,
.illustrated-checkbox-label .checkbox-box {
  background-color: white;
  width: 20px;
  height: 20px;
  border: 2px solid #e6e1e5;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-transition: background 0.2s border-color 0.2s;
  -o-transition: background 0.2s border-color 0.2s;
  transition: background 0.2s border-color 0.2s;
}

.checkbox-text {
  margin: 0px 10px;
  padding: 0;
}

.checkbox-label .checkbox-box:hover,
.illustrated-checkbox-label .checkbox-box:hover {
  background: #e6e1e5;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.checkbox-label .checkbox-box:after,
.illustrated-checkbox-label .checkbox-box:after{
  content: "\2714";
  color: #fff;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  -o-transition: transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

/* TRUEFALSE */
.truefalse-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.true-button,
.false-button {
  font-family: 'Nunito';
  font-size: 16px;
  text-transform: uppercase;
  background: none;
  border-radius: 500px;
  padding: 10px 20px;
  width: 100px;
  cursor: pointer;
  position: relative;
  text-align: center;
  border: none;
}

.true-button.selected::after,
.false-button.selected::after {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border-radius: 500px;
}

.true-button.selected::after {
  border: 4px solid #4DDD98;
}

.false-button.selected::after {
  border: 4px solid #E73333;
}

.true-button,
.true-button.selected {
  border: none;
  margin-right: 20px;
  color: white;
  background-color: #4DDD98;
}

.false-button,
.false-button.selected {
  border: none;
  color: white;
  background-color: #E73333;
}

.true-button:hover {
  background-color: rgba(77, 221, 152, .6);
}

.false-button:hover {
  background-color: rgba(231, 51, 51, .6);
}

/* ILLUSTRATED */
.illustrated-container {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}

.illustrated-checkbox-label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  color: #324650;
  font-size: 16px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.illustrated-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.checkbox-illustrated-container div {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.checkbox-illustrated-container div::after {
  content: url('./assets/zoom.svg');
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: opacity .2s;
  -o-transition: opacity .2s;
  transition: opacity .2s;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.checkbox-illustrated-container div:hover::after {
  opacity: 1;
}

.custom-zoom [data-rmiz-btn-unzoom] {
  background-color: #315397;
  width: 50px;
  height: 50px;
  cursor: pointer
}

.custom-zoom [data-rmiz-btn-unzoom]:hover {
  background-color: #E73333;
}

.custom-zoom [data-rmiz-btn-unzoom-icon] path {
  color: #fff
}

.custom-zoom [data-rmiz-modal-overlay="visible"] {
  opacity: 0.7;
}

.checkbox-illustrated-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 200px;
  margin: 0 auto;
  width: 100%;
}

.checkbox-label-img {
  border-top: 1px solid #dbdbdb;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

/* CONNECT */
.connect-container {
  overflow: hidden;
  clear: both;
}

.connect-fromlist-container {
  float: left;
  width: 40%;
}

.connect-tolist-container {
  float: right;
  width: 40%;
}

.connect-fromlist-element {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.connect-fromlist-element:not(:last-child),
.connect-tolist-element:not(:last-child) {
  margin-bottom: 10px;
}

.connect-tolist-element {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.square {
  background-color: white;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 2px;
  height: 15px;
  width: 15px;
  min-height: 15px;
  min-width: 15px;
  cursor: pointer;
  margin: 0;
}

.square-from{
  margin-left: 12px
}

.square-to{
  margin-right: 12px
}

.square:hover,
.selected,
.linked_selected {
  border: 1px solid #315397;
  background-color: #315397;
}

.linked {
  border: 1px solid #315397;
}

/* ORDERING */
.ordering-element {
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 5px;
  padding: 8px;
  margin: 10px 0;
  cursor: move;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.ordering-element-move {
  max-width: 15px;
  margin-left: 5px;
}

/* ORGANIZE & LEGEND PARTS */
.organize-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  margin-bottom: 20px;
}

.organize-column {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: hidden;
}

.organize-column:not(:last-child){
  margin-right: 20px;
}


.organize-column,
.organize-labels-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
  padding: 0;
  border: 1px solid #D7D9EB;
  overflow: hidden;
}

.organize-base-drop {
  min-height: 70px;
  width: 100%;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  padding: 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.organize-base-drop .organize-label {
  margin: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.organize-column-labels {
  width: 100%;
  min-height: 120px;
  height: 100%;
  padding: 15px 15px 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.organize-column-labels .organize-label {
  width: 100%;
  margin: 0;
  text-align: left;
}

.organize-column-labels .organize-label:not(:last-child) {
  margin-bottom: 10px;
}

.organize-list-title {
  background-color: #D7D9EB;
  width: 100%;
  padding: 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}

.organize-label {
  border: 1px dashed #D7D9EB;
  border-radius: 5px;
  padding: 8px;
  margin: 10px;
  background-color: white;
  cursor: move;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  word-break: break-word;
}

.organize-label:hover {
  background-color: #F5F5FB;
}

/* LEGEND */

.legend-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 24px 0
}

.legend-element {
  border: 1px solid #315397;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
  min-height: 60px;
  width: 100%;
  overflow: hidden;
}

.legend-element:not(:last-child) {
  margin-bottom: 24px;
}

.legend-img-container {
  position: relative;
  width: 450px;
  height: 450px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgb(231, 231, 231);
}

.legend-img {
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

[class$="indicatorContainer"] svg {
  fill: #cccccc !important;
}

/* Loading */
.loading-container {
  width: 100%;
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.spinner {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  z-index: 2;
  width: 20px;
  height: 20px;
}

.path {
  stroke: #fff;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.evaluation-item {
}

.evaluation-item:not(:first-child) {
  border-top: 2px solid #315397;
}

.evaluation-title {
  font-size: 24px;
  font-weight: 700;
}

.evaluation-description {
}

.evaluation-checkbox, .evaluation-radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 24px;

}

.radio-box-disabled, .checkbox-box-disabled {
  opacity: 0.7 !important;
  background-color: #f2f2f2 !important;
  pointer-events: none !important;
}

.evaluation-level {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 24px;
  margin-bottom: 24px;
}



.checkbox-box-error, .radio-box-error {
  border-color: #E73333 !important;
}

.evaluation-level .radio-text {
  margin: 0;
}

.evaluation-alert {
  background-color: #E73333;
  color: #fff;
  padding: 10px;
  border-radius:4px;
}

.evaluation-level-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.evaluation-level-item-radio {
  margin-bottom: 4px;
}

.evaluation-custom-input {
  width: 100%;
}

.checkbox-text-nowrap, .radio-text-nowrap {
  white-space: nowrap;
}

.radio-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.evaluation-item .checkbox-label {
  width: 100%;
}

.evaluation-level .radio-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.radio-label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  color: #324650;
  font-size: 16px;
  width: 100%;
}

.radio-container:not(:last-child){
  margin-bottom: 12px;
}

.evaluation-level .radio-container:not(:last-child){
  margin-bottom: 0;
}

.radio-label .radio-input, .illustrated-radio-label .radio-input {
  display: none;
}

.radio-label .radio-input:checked+.radio-box {
  background: #2e5596;
  border-color: #2e5596;
}

.radio-label .radio-input:checked+.radio-box:after {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}

.radio-label .radio-box {
  background-color: white;
  width: 20px;
  height: 20px;
  border: 2px solid #e6e1e5;
  border-radius: 500px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-transition: background 0.2s border-color 0.2s;
  -o-transition: background 0.2s border-color 0.2s;
  transition: background 0.2s border-color 0.2s;
}

.radio-text {
  margin: 0px 10px;
  padding: 0;
}

.radio-label .radio-box:hover {
  background: #e6e1e5;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.radio-label .radio-box:after {
  content: "\2714";
  color: #fff;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  -o-transition: transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.atlas-image-container {
    width: 100%;
    min-height: 100px;
    max-height: 100px;
    height: 100px;
    position: relative;
    margin-top: 20px;
}


.atlas-image-container img {
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

@media (min-width: 600px) {
  .atlas-image-container img {
    -o-object-position: left;
       object-position: left;
  }
}
